import React from 'react';
import Header from './common/Header';
import SEO from './common/SEO';
import logo from '../assets/images/hqz-android.png';
import bgvid from '../assets/videos/android.mp4';

const Android = () => {
  return (
    <>
        <SEO title="Download HQZ Android App" />
        <div className="position-relative vh-100 overflow-hidden">
            <video width="320" height="240" className="object-fit-cover w-100 h-100 position-absolute top-0 start-0" autoPlay loop muted playsInline>
                <source src={bgvid} type="video/mp4" />
            </video>
            <Header />
            <div className="container d-flex justify-content-center justify-content-md-end position-relative align-items-center h-100">
                <div className="mw-300">
                    <img className="w-100 mb-3" src={logo} alt="" />
                    <div className="px-3">
                        <p className="mb-3 text-white fw-medium">
                            Headquarterz is the official social video sharing platform for the next generation of content creators. Download Today.
                        </p>
                        <div>
                            <a href="https://play.google.com/store/apps/details?id=com.hqz.app" target="_blank" rel="noreferrer" className="btn btn-success w-100 mb-3" aria-label="Download HQZ Android App">Download</a>
                            <a href="https://play.google.com/store/apps/details?id=com.hqz.app" target="_blank" rel="noreferrer" className="btn btn-success w-100 mb-3" aria-label="Download HQZ Android App">Connect</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
};

export default Android;