import React from 'react';
import Header from './common/Header';
import Footer from './common/Footer';
import SEO from './common/SEO';
import appledownload from '../assets/images/appstore.png';
import androiddownload from '../assets/images/playstore.png';
import qrcode from '../assets/images/hqz-qrcode.jpg';
import vid1 from '../assets/videos/hqz-mobilevid.mp4';
import vid2 from '../assets/videos/hqz-mobilevid2.mp4';
import vid3 from '../assets/videos/hqz-mobilevid3.mp4';

const Home = () => {
  return (
    <>
        <SEO />
        <div className="position-relative main-page">
            <Header />
            <div className="container d-flex flex-column flex-md-row justify-content-center position-relative align-items-center h-100 p-3 py-3 p-md-4 z-3 mb-5 min-vh-100">
                <div className="text-center text-md-start col-lg-5 me-md-3 mb-3 mb-md-0">
                    <video width="305" height="663" autoPlay loop muted playsInline>
                        <source src={vid1} type="video/mp4" />
                    </video>
                </div>
                <div className="w-100 container">
                    <h2 className="text-white fw-medium text-center text-md-start">Where Clips<br /> Go Viral</h2>
                    <div className="d-flex gap-5 mt-5">
                        <div className="d-none d-lg-block">
                            <img src={qrcode} width="145" className="rounded" alt="" />
                        </div>
                        <div className="mx-auto mx-md-0">
                            <h4 className="text-white fw-medium text-center text-md-start">Download Heaquarterz</h4>
                            <h5 className="text-white fw-medium d-none d-lg-block">Scan the QR Code</h5>
                            <div className="d-flex gap-2">
                                <a href="https://apps.apple.com/zm/app/hqz/id6445936014?platform=iphone" target="_blank" rel="noreferrer" aria-label="Download HQZ iOS App">
                                    <img src={appledownload} alt="Download HQZ iOS App" width="180px" className="mw-100" />
                                </a>
                                <a href="https://play.google.com/store/apps/details?id=com.hqz.app" target="_blank" rel="noreferrer" aria-label="Download HQZ Android App">
                                    <img src={androiddownload} alt="Download HQZ Android App" width="180px" className="mw-100" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container d-flex flex-column flex-md-row justify-content-center position-relative align-items-center h-100 p-3 py-3 p-md-4 z-3 mb-5 min-vh-100">
                <div className="text-center text-md-start col-lg-5 me-md-3 mb-3 mb-md-0">
                    <video width="305" height="663" autoPlay loop muted playsInline>
                        <source src={vid2} type="video/mp4" />
                    </video>
                </div>
                <div className="w-100 container">
                    <h2 className="text-white fw-medium text-center text-md-start">Discover the Next Generation of Content Creators.</h2>
                </div>
            </div>
            <div className="bottom-gradient">
                <div className="container d-flex flex-column flex-md-row justify-content-center position-relative align-items-center h-100 p-3 py-3 p-md-4 z-3 mb-5 min-vh-100">
                    <div className="text-center text-md-start col-lg-5 me-md-3 mb-3 mb-md-0">
                        <video width="305" height="663" autoPlay loop muted playsInline>
                            <source src={vid3} type="video/mp4" />
                        </video>
                    </div>
                    <div className="w-100 container">
                        <h2 className="text-white fw-medium text-center text-md-start">Stay In the Mix with Who and What's Trending Daily.</h2>
                    </div>
                </div>
                <div className="container d-flex justify-content-center position-relative align-items-center h-100 p-3 py-3 p-md-4 z-3 min-vh-100">
                    <div className="w-100 container text-center">
                        <h2 className="text-white fw-medium text-center">Launch a Channel or Subscribe to Your Favorite Creators.</h2>
                        <div className="d-flex flex-column justify-content-center gap-2 mt-5">
                            <h4 className="text-white fw-medium text-center">Download Heaquarterz</h4>
                            <h5 className="text-white fw-medium d-none d-lg-block">Scan the QR Code</h5>
                            <img src={qrcode} width="145" className="rounded m-auto mb-3 d-none d-lg-block" alt="" />
                            <div className="d-flex justify-content-center gap-2">
                                <a href="https://apps.apple.com/zm/app/hqz/id6445936014?platform=iphone" target="_blank" rel="noreferrer" aria-label="Download HQZ iOS App">
                                    <img src={appledownload} alt="Download HQZ iOS App" width="180px" className="mw-100" />
                                </a>
                                <a href="https://play.google.com/store/apps/details?id=com.hqz.app" target="_blank" rel="noreferrer" aria-label="Download HQZ Android App">
                                    <img src={androiddownload} alt="Download HQZ Android App" width="180px" className="mw-100" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    </>
  );
};

export default Home;
