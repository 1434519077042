import React from 'react';
import Header from './common/Header';
import Footer from './common/Footer';
import SEO from './common/SEO';

const Terms = () => {
  return (
    <>
      <SEO title="Terms & Conditions - HQZ" />
      <div className="position-relative bg-black">
        <Header />
        <div className="container py-5 text-white">
            <h1 className="mb-5">Website Terms of Use</h1>
            <p><em>Effective Date: September 30, 2024</em></p>

            <p>Welcome to our website.  The websites at www.hqz.gg, www.hqztv.com and www.hqz.app, and the associated interactive sports-themed social video sharing 
                platform (collectively the “Sites”) are operated by Headquarterz Inc. (“we”, “us” or “Headquarterz”).  By accessing or using the Sites you (you are 
                referred to hereafter as “you” or “your”) signify that you have read, understand and agree to be bound by these Website Terms of Use.  Your access to 
                and use of the Headquarterz online platform additionally requires your agreeing to and abiding by Headquarterz' Platform User Agreement.</p>
            <p>We reserve the right, at our sole discretion, to change, modify, add, or delete portions of these Terms of Use at any time without further notice. We 
                might do this if we were to update the functionality of the Sites, if we combine multiple apps or services operated by us or our affiliates into a 
                single combined service or app, or in the event of regulatory changes.  If we do this, we will post the changes to these Terms of Use on this page 
                and we will indicate at the top of this page the date upon which the most recent version of these terms became effective. Your continued use of the 
                Sites after any such changes constitutes your acceptance of the new Terms of Use.</p>
            <p>If you do not agree to abide by these or any future Terms of Use or the Privacy Policy, do not use or access (or continue to use or access) the Sites 
                or the platform available to registered users made available at the Sites.  These Terms of Use should be read together with the User Agreement terms 
                that you (and, as appropriate, your organization) must agree to as a precondition for accessing and using Headquarterz' platform.</p>
            <p><b>Please read these Terms of Use carefully, as they contain important information regarding your legal obligations, remedies, and rights, including 
                various limitations and exclusions.</b></p>
            <p>We reserve the right to withdraw or amend the Sites, and any service or material we provide on the Sites, in our sole discretion without notice.  We 
                will not be liable if for any reason all or any part of the Sites is unavailable at any time or for any period.  From time to time, we may restrict 
                access to some parts of the Sites, or the entire Sites, to users.</p>
            
            <h2>Proprietary Rights in Sites</h2>
            <p>All content on the Sites, including designs, computer code, text, graphics, pictures, music, sound, and other works and their selection and 
                arrangement (collectively, the “Sites Content”) is the property of Headquarterz, its licensors, or other third parties. No Sites Content may be 
                modified, copied, distributed, framed, reproduced, republished, downloaded, scraped, displayed, posted, transmitted, or sold in any form or by any 
                means, in whole or in part, without Headquarterz’ prior written permission. You are granted a limited license to access and use the Sites and the 
                Sites Content in accordance with these Terms of Use, including any password-restricted areas to which you have properly gained access, solely for 
                your internal. You may not upload or republish Sites Content on any Internet, intranet or extranet site or incorporate the information in any other 
                database or compilation.  Any use of the Sites Content outside this limited license is strictly prohibited. Such license does not permit use of any 
                data mining, robots, scraping or similar data gathering or extraction methods. Unless explicitly stated herein, nothing in these Terms of Use shall be 
                construed as conferring any license to intellectual property rights.</p>
            <p>The Sites include functionality through which users can post messages and content and comment about and  respond to the messages and content of others.  
                Headquarterz does not assert any ownership over the information and content posted or uploaded by its members onto the Sites or the platform (“User 
                Content”)</p>
            
            <h2>Wireless Features</h2>
            <p>The Sites or any mobile application associated with the Sites may offer certain features and services that are available to you via your wireless Internet 
                device.  These features and services may include the ability to access the Sites' or an application associated with the Sites') features, and the ability to 
                upload content to the Sites or associated applications, receive messages from the Sites or any associated app, and download applications to your wireless 
                Internet device (collectively, “Wireless Features”).  Standard messaging, data, and other fees may be charged by your carrier to participate in Wireless 
                Features. Fees and charges may appear on your wireless bill or be deducted from your pre-paid balance. Your carrier may prohibit or restrict certain 
                Wireless Features and certain Wireless Features may be incompatible with your carrier or wireless Internet Device. You should check with your carrier 
                to find out what plans are available and how much they cost.  Contact your carrier with questions regarding these issues.</p>
            <p>You agree that as to the Wireless Features for which you are registered for, we may send communications to your wireless device regarding us or other 
                parties.  Further, we may collect information related to your use of the Wireless Features.  If you have registered via the Sites or via an associated 
                app for Wireless Features, then you agree to notify Headquarterz of any changes to your wireless contact information (including phone number) and update 
                your accounts for the Sites to reflect the changes.</p>
            
            <h2>Prohibited Conduct</h2>
            <p>You may not use this Sites for any unlawful purpose.  You may not use the Sites in any manner that could disable, overburden, damage, or impair the site or 
                interfere with any other party's use of the Sites, including their ability to engage in real time activities through the Sites.</p>
            <p>You may not use any robot, spider, scraper, automated scripts or other automated means to access the Sites or content or services provided on the Sites 
                for any purposes. You shall not attempt to make the Sites unavailable through denial-of-service attacks or similar means or use the Sites in a manner that 
                could damage, disable, or impair the Sites.</p>
            <p>Headquarterz may disable your access to the Sites at any time, for any or no reason, and without notice to you, subject to the terms of the User Agreement 
                (as applicable), including if you have failed to comply with any of the provisions of these Terms.</p>
            
            <h2>Privacy</h2>
            <p>You agree that you have read, understood and accept the terms of Headquarterz' Privacy Policy. This policy governs the collection, use and sharing of 
                personal and non-personal information from you when using the Sites, including the portions of the site that require user name/password credentials for 
                access.</p>
            
            <h2>Your Linking to the Sites</h2>
            <p>You are granted a limited, non-exclusive right to create a hyperlink to the Sites's home page, provided such link does not portray Headquarterz or any of 
                its products and services in a false, misleading, derogatory or otherwise defamatory manner and does not create the appearance of affiliation with or 
                sponsorship by Headquarterz.  You may not use any logo or other proprietary graphic or trademark of Headquarterz to link to any page of this Sites without 
                the express written permission of Headquarterz.  This limited right may be revoked at any time.</p>
            
            <h2>Links to Other Websites</h2>
            <p>The Sites may contain links to other websites that are not under the control of Headquarterz.  The inclusion of any link does not imply endorsement by 
                Headquarterz of such site and Headquarterz is not responsible for the content of such linked sites. If you decide to leave the Sites and access a third-party 
                website, you do so at your own risk.</p>
            
            <h2>Disclaimer of Warranties</h2>
            <p><b>The Sites, Sites Content and the other content and services made available on or via the Sites are provided on an “as is” and “as available” basis.  
                Headquarterz does not make and disclaims all express and implied warranties and representations, including, but not limited to, any implied warranties of 
                merchantability, fitness for a particular purpose, title, and non-infringement, or warranties that may arise out of course of dealing, course of performance, 
                usage, or trade practice, with regard to the Sites, the Sites Content, or any advice or services provided through the Sites to the extent permitted by law.  
                Headquarterz does not warrant that access to the Sites or Sites Content will be uninterrupted or error-free, that defects in the site will be corrected, or 
                that the Sites is free from viruses or other harmful components.</b></p>
            <p><b>You expressly agree that use of the Sites, including all content, interactions with other users, data or software distributed by, downloaded or accessed 
                from or through the Sites, is at your sole risk.</b></p>
            
            <h2>Limitation of Liability</h2>
            <p><b>Under no circumstances shall Headquarterz, its partners, contributors, agents, employees, directors, or affiliates be liable for any indirect, incidental, 
                special, exemplary, punitive, or consequential damages (even if it has been advised of the possibility of such damages), including but not limited to such 
                damages for lost data, due to your use of the Sites, interactions with other users, or due to your reliance on any of the content contained or the services 
                provided on or through the Sites.</b></p>
            
            <h2>Indemnification</h2>
            <p>You agree to indemnify and hold harmless Headquarterz, its officers, employees, agents, subsidiaries, affiliates and other partners, from and against any 
                claims, harm, actions or demands, damages, liabilities and settlements including without limitation, reasonable legal and accounting fees, arising out of, 
                resulting from, or alleged to arise out of or result from: (1) your use of the Sites; (2) your violation of these Terms of Use or the User Agreement, 
                including, but not limited to, any use of the Sites' content, services, and products other than as expressly authorized in these Terms of Use; or (3) your 
                use of any information obtained from the Sites.</p>
            
            <h2>Comments and Suggestions</h2>
            <p>You acknowledge and agree that any questions, comments, suggestions, ideas, feedback or other information about the Sites or the service provided on or 
                through the Sites (“Comments”), provided by you to Headquarterz are non-confidential and that Headquarterz shall be entitled to the unrestricted use and 
                dissemination of these Comments for any purpose, commercial or otherwise, without acknowledgment or compensation to you.</p>
            
            <h2>Governing Law and Venue</h2>
            <p>These Terms of Use shall be governed by the laws of the Commonwealth of Massachusetts and the applicable federal laws of the United States of America.  
                All disputes arising under, or in any way connected with membership in or use of the Sites, shall be litigated exclusively in the state and federal 
                courts residing in Massachusetts, and in no other court or jurisdiction. You hereby submit to the jurisdiction of the state and federal courts sitting 
                in Massachusetts.</p>
            
            <h2>Miscellaneous Terms</h2>
            <p>HEADQUARTERZ™, HQZ™, and the Headquarterz and HQZ Logos are trademarks of Headquarterz.  You agree not to display or use these trademarks in any manner without 
                Headquarterz' prior, written permission.</p>
            <p>Headquarterz may assign this agreement at any time. You may not assign or transfer this agreement.</p>
            <p>If any provision of this Agreement is held to be unenforceable for any reason, the remaining provisions will be unaffected and remain in full force and effect.</p>
            <p>The failure of Headquarterz to exercise or enforce any right or provision of these Terms of Use shall not constitute a waiver of such right or provision in any 
                that or any instance.</p>
            <p>These Terms of Use, our Privacy Policy, and any additional User Agreement relating to your use of the Sites constitute the sole and entire agreement between you 
                and Headquarterz regarding the Sites and supersede all prior and contemporaneous understandings, agreements, representations, and warranties, both written and 
                oral, regarding the Sites.</p>
            <p>No waiver by Headquarterz of any term or condition set out in these Terms of Use shall be deemed a further or continuing waiver of such term or condition or a 
                waiver of any other term or condition, and any failure of Headquarterz to assert a right or provision under these Terms of Use shall not constitute a waiver 
                of such right or provision.</p>
            <p>If any provision of these Terms of Use is held by a court or other tribunal of competent jurisdiction to be invalid, illegal, or unenforceable for any reason, 
                such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms of Use will continue in full force and 
                effect.</p>

            <h2>Effective Date</h2>
            <p>These Terms of Use were last revised on September 30, 2024.</p>
            
            <h2>Contact Us</h2>
            <p>If you have any questions or suggestions regarding these Terms of Use, please contact Headquarterz at:</p>
            <p>Headquarterz Inc.<br />
                3133 W Frye Rd,<br />
                Chandler, AZ 85226 USA</p>
            <p>Email us: legal@hqz.gg</p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Terms;