import React from 'react';
import { Helmet } from 'react-helmet-async';
import socialimgdefault from '../../assets/images/hqzsocial.jpg';

const SEO = ({title, description, socialimg, robot}) => {
  return (
    <Helmet>
        <title>{title ? title : "HQZ | Where Clips Go Viral"}</title>
        <meta name="description" content={description ? description : "Headquarterz is the official social video sharing platform for the next generation of content creators. Available now on iOS and Android!"} />
        <meta name="robots" content="max-image-preview:large" />
        <meta property="og:title" content={title ? title : "HQZ | Where Clips Go Viral"} />
        <meta property="og:description" content={description ? description : "Headquarterz is the official social video sharing platform for the next generation of content creators. Available now on iOS and Android!"} />
        <meta property="og:url" content="https://hqz.app/" />
        <meta property="og:image" content={socialimg ? socialimg : socialimgdefault} />
        <meta property="og:image:secure_url" content={socialimg ? socialimg : socialimgdefault} />
        <meta name="twitter:title" content={title ? title : "HQZ | Where Clips Go Viral"} />
        <meta name="twitter:description" content={description ? description : "Headquarterz is the official social video sharing platform for the next generation of content creators. Available now on iOS and Android!"} />
        <meta name="twitter:image" content={socialimg ? socialimg : socialimgdefault} />
        <meta name="robots" content={robot ? robot : "index,follow"} />
    </Helmet>
  );
};

export default SEO;