import React from 'react';
import Header from './common/Header';
import Footer from './common/Footer';
import SEO from './common/SEO';

const UserAgreement = () => {
  return (
    <>
      <SEO title="Platform User Agreement - HQZ" />
      <div className="position-relative bg-black">
        <Header />
        <div className="container py-5 text-white">
            <h1 className="mb-5">Platform User Agreement</h1>
            <p><em>Effective Date: September 30, 2024</em></p>

            <p>This Platform User Agreement (“Agreement”) is a binding agreement entered into on the date upon which you click “I Agree” after reading the terms below 
                (the “Effective Date”) and is between you (“End User” or “you” (which includes your child or a minor under your guardianship, as appropriate)) and 
                Headquarterz Inc. (“Headquarterz”). This Agreement governs your access to and use of Headquarterz's platform that allows you to review, post and share a 
                range of content, including audio and video content (collectively the “Services”).  The Headquarterz online Services platform that enables and facilitates 
                the Services, together with any supporting mobile application, are referred to herein collectively as the “Platform”). The Platform is licensed, not sold, 
                to you.</p>
            <p>By using the Platform and the Services, you (a) acknowledge that you have read and understand this Agreement; (b) represent that you are 13 years of age 
                or older; (c) agree that to the extent you are registering to enable use of the Platform by your child or a minor under your guardianship you are 
                responsible for such minor's use of the Platform and Services and for his or her compliance with the terms of this Agreement; (d) agree to the terms 
                of the Headquarterz Privacy Policy and (e) accept this Agreement and agree that you (and/or such child) are legally bound by its terms.  If you are 
                accessing or using the Services on behalf of a business or entity, then “you” and “your” includes you and that business or entity, you represent and 
                warrant that you are an authorized representative of the business or entity with the authority to bind the entity to this Agreement, and that you agree 
                to these Terms on the entity's behalf, and (c) your business or entity is legally and financially responsible for your access or use of the Services as 
                well as for the access or use of your account by others affiliated with your entity, including any employees, agents or contractors. If you do not agree 
                to the terms of this Agreement, you may not register to use the Services or access or use the Platform.</p>
            <p>Arbitration notice and class action waiver notice for users in the United States: These terms contain an arbitration clause and a waiver of rights to bring 
                a class action against us. Except for certain types of disputes mentioned in that arbitration clause, and subject to your right to opt out of the 
                arbitrations terms as provided for in Section 22 below, you and Headquarterz agree that disputes between us will be resolved by mandatory binding 
                arbitration. You and Headquarterz waive any right to participate in a class-action lawsuit or class-wide arbitration.</p>

            <h2>Registration/Collection and Use of Your Information</h2>
            <p>You will be required to register to use the Services, and in the course of doing so you will submit information about you for purposes of creating a user 
                profile.  By submitting information that identifies you or others directly or indirectly, you agree to the use of that information as described in the 
                Privacy Policy, the terms of which are incorporated by reference into this Agreement.  You acknowledge that when you download, install, or use the Platform, 
                Headquarterz may use automatic means (including, for example, cookies and web beacons) to collect information about your device and about your activities.  
                By downloading, installing, using, and providing information to or through the Platform, you consent to all actions taken by Headquarterz with respect to 
                your information and User Content (as defined below) in compliance with the Privacy Policy. </p>

            <h2>User Name/Password Protection</h2>
            <p>You also will be required to choose a username and password for your account. You agree that you are entirely responsible for safeguarding and maintaining 
                the confidentiality of the username and password you use to access the Platform. You authorize Headquarterz to assume that any person using the Platform 
                with your username and password is you or is authorized to act for you. You agree to notify us immediately if you suspect any unauthorized use of your 
                account.</p>

            <h2>Your Responsibilities</h2>
            <p>(a) You have and will retain sole control of all access to and use of the Platform by any individual (including a minor) using your Platform logon 
                credentials, including any: (i) information, instructions, or materials posted by or provided by any individual within the Platform; (ii) third-party 
                content accessed via the Platform; and (iii) conclusions, decisions, or actions based on such use.</p>
            <p>(b) You have and will retain sole responsibility for: (i) all information, data, and other content, in any form or medium, that is collected, downloaded, 
                or otherwise received, directly or indirectly from you, or from any individual using the Platform under your logon credentials, by or through the Platform 
                or that incorporates or is derived from the storage, compilation or management of such information, data, or content by or through the Platform; (ii) all 
                information, instructions, and materials provided by or on behalf of you or by any individual using the Platform under your logon credentials; (iii) your 
                information technology infrastructure, including computers, software, databases, electronic systems (including database management systems), and networks, 
                whether operated directly by you or through the use of third-party services; (iv) the security and use of your Platform access credentials; and (v) all 
                access to and use of the Services and the Platform directly or indirectly by you or any individual using the Platform under your logon credentials, with 
                or without your knowledge or consent, including all results obtained from, and all conclusions, decisions, and actions based on, such access or use.</p>

            <h2>Pricing and Payment</h2>
            <p>(a) Pricing. If you elect to access any component of the Services or Platform for which there is a fee, you agree to pay all fees and charges associated 
                with your account on a timely basis. All such fees and charges (including any taxes and late fees, as applicable) will be charged on your credit card. 
                Each user agrees to maintain valid credit card information as part of your account information when applicable. Headquarterz uses third party payment 
                intermediaries such as Paypal or Stripe for payment processing.</p>
            <p>(b) Subscriptions and Purchases. The Platform may allow you to access digital content on a pay per view basis, subscription basis, rent, or purchases. 
                The basis on which digital content is available via the Platform will be indicated on the product detail page for which you may purchase the digital 
                content. Subject to your payment of any applicable fees, purchases, subscriptions, rent or pay per view, Headquarterz grants you a non-exclusive, 
                non-transferrable, personal, non-sub licensable, limited right and license to view the video stream based upon the applicable fees, purchases, subscriptions, 
                rent or pay per view selected by you.  Headquarterz makes no guarantees as to the resolution and quality of your digital content when streaming. The quality 
                and speed of your stream of digital content has many different variables, including your connection speed, location, download speeds, devices, player and 
                bandwidth. The digital content available under specific payment plans, including pay per view, subscription, membership, or rent will change from time to 
                time at the sole discretion of Headquarterz. Headquarterz makes no guarantee as to the availability of a specific payment plan.</p>
            <p>By purchasing a payment plan, you expressly agree that we are authorized to charge your selected payment plan on the payment method you designate. You can 
                update change this information at anytime by logging into your video library, and clicking the settings tab under the username.</p>
            <p>Receipts are sent once the charge is successful to the registered email account. Your subscription will continue in effect unless and until you cancel 
                your subscription or we terminate it. You must cancel your payment plan according to the terms of that specific plan prior to the next charge in order 
                to avoid billing.</p>
            <p>(c) Pricing Increases. Headquarterz may increase the pricing at its sole discretion.</p>

            <h2>User Content</h2>
            <p>(a) The Platform and the Services may allow you to submit, upload, publish or otherwise make available to Headquarterz through the Services text authored 
                by you or others and data arranged and compiled by you or others (“User Content”).  Any User Content provided by you remains your property, or the property 
                of others where applicable.  However, by posting User Content within the Platform and submitting the User Content to Headquarterz, you grant Headquarterz 
                a worldwide, perpetual, irrevocable, transferable, royalty-free license, with the right to use, edit, copy, modify, make derivative works of and distribute 
                (solely at Headquarterz's direction) such User Content in all formats and distribution channels now known or hereafter devised (including in connection with 
                the Services and Headquarterz' business and on third-party sites and services), without further notice to or consent from you, and without the requirement 
                of payment to you or any other person or entity.  Without limiting the generality of the foregoing license, you grant to Headquarterz the right to use your 
                User Content without the obligation to pay royalties to any third party, including, but not limited to, a sound recording copyright owner (e.g., a record 
                label), a musical work copyright owner (e.g., a music publisher), a performing rights organization (e.g., ASCAP, BMI, SESAC, etc.) (a “PRO”), a sound 
                recording PRO (e.g., SoundExchange), any unions or guilds, and engineers, producers or other royalty participants involved in the creation of User Content. 
                You further grant to Headquarterz a royalty-free license to use your user name, image, voice, and likeness to identify you as the source of any of your User 
                Content; provided, however, that your ability to provide an image, voice, and likeness may be subject to limitations due to age restrictions.</p>
            <p>(b) You represent and warrant that: (i) you either are the sole and exclusive owner of all User Content or you have all rights, licenses, consents and 
                releases necessary to grant Headquarterz the license to the User Content as set forth above; (ii) neither the User Content, nor your submission, uploading, 
                publishing or otherwise making available of such User Content, nor Headquarterz ’s use of the User Content as permitted herein will infringe, misappropriate 
                or violate a third party's intellectual property or proprietary rights, or rights of publicity or privacy, or result in the violation of any applicable law or 
                regulation; and (iii) if you only own the rights in and to a sound recording, but not to the underlying musical works embodied in such sound recordings, 
                then you have secured all permissions, clearances from, or are authorized by, the owner(s) of any part of the content to submit it to the Platform.  We 
                eserve the right, at any time and without prior notice, to remove or disable access to content, including your User Content or the User Content of others, 
                at our discretion for any reason or no reason.  Some of the reasons we may remove or disable access to content may include finding the content objectionable, 
                in violation of these Terms or otherwise harmful to the Service, the Platform or to our users.</p>
            <p>(c) The Services and the Platform are intended to manage and organize the User Content and enable the efficient distribution and use of the User Content that 
                you submit.  Headquarterz will exert commercially reasonable efforts to carry out the Services in an error-free manner.  You understand that Headquarterz 
                does not warrant accuracy or a lack of data processing errors.  Furthermore, you agree to engage in validation review of the User Content as it appears in 
                the Platform to independently ensure the integrity and usability of the User Content, and to inform Headquarterz of any errors, inconsistencies or issues 
                that should be addressed.</p>
            <p>(d) Whenever you access or use a Platform feature that allows you to upload or transmit User Content through the Services (including via certain third party 
                social media platforms such as Instagram, Facebook, YouTube, Twitter), or to make contact with other users of the Services, you must comply with the standards 
                of this Agreement. You may also choose to upload or transmit your User Content on sites or platforms hosted by third parties. If you decide to do this, you 
                must comply with their content guidelines as well as with the standards set out in this Agreement. These features may not be available to all users of the 
                Platform, and we have no liability to you for limiting your right to certain features of the Platform.</p>

            <h2>Prohibited Uses of Platform</h2>
            <p>(a) You agree not to access (or attempt to access) the Platform by any means other than through the website platform or any mobile app interface that is 
                provided by Headquarterz.  You agree that you will not copy or scrape, through manual or automated means, any data from the Platform apart from your use 
                of the data needed by or useful to you for your individual use consistent with the intended use of the Platform.  You agree that you will not engage in 
                any activity that interferes with or disrupts the Platform (or the servers and networks which are connected to the Platform). You agree that you will not 
                reproduce, duplicate, copy, sell, trade or resell the Platform for any purpose. You agree that you are solely responsible for (and that Headquarterz has no 
                responsibility or liability to you or to any third party for) any breach of your obligations under this Agreement and for any consequences (including any 
                loss or damage which Headquarterz may suffer) of any such breach.</p>
            <p>(b) You agree that you shall not transmit to Headquarterz or upload to or through the Platform (whether as User Content or otherwise) any Harmful Code or 
                use or misappropriate the Platform for your own commercial gain. “Harmful Code” shall mean any software (sometimes referred to as “viruses,” “worms,” “Trojan 
                horses,” “time bombs,” “time locks,” “drop dead devices,” “traps,” “access codes,” “cancelbots” or “trap door devices”) that: (a) is designed to damage, 
                disrupt, disable, harm, impair, interfere with, intercept, expropriate or otherwise impede in any manner, any data, storage media, program, system, equipment 
                or communication, based on any event, including for example but not limited to (i) exceeding a number of copies, (ii) exceeding a number of users, (iii) 
                passage of a period of time, (iv) advancement to a particular date or other numeral, or (v) use of a certain feature; or (b) would enable an unauthorized 
                person to cause such result; or (c) would enable an unauthorized person to access another person's information without such other person's knowledge and 
                permission.</p>
            <p>(c) Without limiting the foregoing and by way of example only, users may not:</p>
            <ul>
                <li>Generate or facilitate unsolicited commercial email (“spam”). Such activity includes, but is not limited to:
                    <ul>
                        <li>sending messages in violation of the CAN-SPAM Act under U.S. law or any other applicable anti-spam law;</li>
                        <li>imitating or impersonating another person or his, her or its email address, or creating false accounts for the purpose of sending spam;</li>
                        <li>sending messages to users who have asked not to be contacted;</li>
                        <li>selling, exchanging or distributing to a third party the contact information of any person without such person's knowledge of, and continued consent 
                            to, such disclosure; and</li>
                        <li>using Headquarterz contacts in violation of Headquarterz policy, as determined by Headquarterz, including, for example, as indicated by low 
                            response rates from those persons contacted.</li>
                    </ul>
                </li>
                <li>Engage in child sexual abuse and exploitation of any kind;</li>
                <li>Send, upload, distribute or disseminate any unlawful, defamatory, harassing, abusive, fraudulent, infringing, intimidating obscene, or otherwise objectionable 
                    content, or offer to do the same.</li>
                <li>Intentionally distribute viruses, worms, defects, Trojan horses, corrupted files, hoaxes, or any other Harmful Code or items of a destructive or deceptive 
                    nature.</li>
                <li>Conduct or forward pyramid schemes or similar programs.</li>
                <li>Transmit content that may be harmful to minors.</li>
                <li>Impersonate another person (via the use of an email address or otherwise) or otherwise misrepresent yourself or the source of any email.</li>
                <li>Transmit another's intellectual property or other proprietary or confidential information without such owner's or licensor's permission.</li>
                <li>Violate the legal rights (such as rights of privacy and publicity) of others.</li>
                <li>Promote or encourage illegal activity.</li>
                <li>Interfere with other Headquarterz users' enjoyment of the Platform.</li>
                <li>Create user accounts by automated means or under false or fraudulent pretenses.</li>
                <li>Sell, trade, resell or otherwise exploit for any unauthorized commercial purpose, or transfer, any Headquarterz account.</li>
                <li>Modify, adapt, translate, or reverse engineer any portion of the Platform.</li>
                <li>Reformat or frame any portion of the web pages that are part of the Platform without Headquarterz' explicit written permission.</li>
                <li>Contact other Headquarterz users about multi-level marketing (MLM) programs or any topics Headquarterz considers detrimental to its users.</li>
                <li>Use the Platform in a manner that may create a conflict of interest or undermine the purposes of the Services, such as trading reviews with other users or 
                    writing or soliciting fake reviews.</li>
                <li>Create multiple Headquarterz accounts without permission.</li>
                <li>Bypass any limitations or suspensions of functionality.</li>
                <li>Provide false information.</li>
            </ul>
            
            <h2>License Grant</h2>
            <p>Subject to the terms of this Agreement, Headquarterz grants to you a limited, non-exclusive, non-sublicensable, nontransferable and revocable license to download, 
                install, and use the Platform for the Term (as defined in Section 14 below) strictly in accordance with the Platform's documentation and solely for your private, 
                non-commercial use of the Services.  For the avoidance of doubt, this license does not entitle you to distribute or re-sell the Platform or any data accessed 
                through the Platform.  You acknowledge and agree that no rights are licensed with respect to sound recordings and the musical works embodied therein that are 
                made available from or through the Platform.</p>

            <h2>License Restrictions</h2>
            <p>You shall not:</p>
            <p>(a) copy the Platform, except as expressly permitted by this Agreement;</p>
	        <p>(b) modify, translate, adapt, or otherwise create derivative works or improvements, whether or not patentable, of the Platform;</p>
    		<p>(c) reverse engineer, disassemble, decompile, decode, or otherwise attempt to derive or gain access to the source code of the Platform or any part thereof;</p>
    		<p>(d) remove, delete, alter, or obscure any trademarks or any copyright, trademark, patent, or other intellectual property or proprietary rights notices from the 
                Platform, including any copy thereof;</p>
    		<p>(e) rent, lease, lend, sell, sublicense, assign, distribute, publish, transfer, or otherwise make available the Platform, the data accessed through the Platform, 
                or any features or functionality of the Platform, to any third party for any reason, including by making the Platform available on a network where it is capable 
                of being accessed by more than one device at any time; or</p>
    		<p>(f) remove, disable, circumvent, or otherwise create or implement any workaround to any copy protection, rights management, or security features in or protecting 
                the Platform.</p>

            <h2>Reservation of Rights</h2>
            <p>The Platform and its entire contents (other than materials you submit), features, and functionality (including but not limited to all information, software, text, 
                displays, images, video, and audio, and the design, selection, and arrangement thereof) are owned by Headquarterz, its licensors, or other providers of such 
                material and are protected by United States and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights 
                laws.  You acknowledge and agree that the Platform is provided under license, and not sold, to you. You do not acquire any ownership interest in the Platform or 
                the data accessible through the Platform under this Agreement, nor do you acquire any other rights thereto other than to use the Platform in accordance with the 
                license granted, and subject to all terms, conditions, and in accordance with the Platform's documentation.  Headquarterz reserves and shall retain its entire 
                right, title, and interest in and to the Platform, including all copyrights, trademarks, and other intellectual property rights therein or relating thereto, except 
                as expressly granted to you in this Agreement.</p>
            <p>You or a third party own all content that you or any individual using the Platform under your logon credentials submit to the Platform, and Headquarterz disclaims 
                ownership of or responsibility for such content.  You hereby irrevocably grant all rights and permissions in or relating to such content as is necessary or useful 
                to Headquarterz to enforce this Agreement and exercise Headquarterz' rights and perform Headquarterz' obligations hereunder.</p>
            
            <h2>You Do Not Financially Benefit from Platform</h2>
            <p>You acknowledge and agree that we may generate revenues, increase goodwill or otherwise increase our value from your use of the Services, including, by way of 
                example and not limitation, through the sale of advertising, sponsorships, promotions and usage data and, except as specifically permitted by us in these Terms 
                or in another agreement you enter into with us, you will have no right to share in any such revenue, goodwill or value whatsoever. You further acknowledge that, 
                except as specifically permitted by us in these Terms or in another agreement you enter into with us, you (i) have no right to receive any income or other 
                consideration from any User Content or your use of any musical works, sound recordings or audiovisual clips made available to you on or through the Platform, 
                including in any User Content created by you, and (ii) are prohibited from exercising any rights to monetize or obtain consideration from any User Content within 
                the Platform or on any third party service (e.g., you cannot claim User Content that has been uploaded to a social media platform such as YouTube for 
                monetization).</p>

            <h2>Protecting Children</h2>
            <p>The Services and the Platform comply with applicable child safety laws and regulations which includes, but is not limited to, Headquarterz having a process in place 
                to report confirmed cases of child sexual abuse and exploitation (“CSAE") to the National Center for Missing and Exploited Children.  Headquarterz takes appropriate 
                action, including but not limited to removing child sexual abuse material (“CSAM”), after obtaining actual knowledge of it, in accordance with the standards in 
                this Agreement and relevant laws.  Please submit questions or concerns about CSAE via email using legal@hqz.gg.  Also, the Platform, including its associate 
                app, includes a mechanism for you to submit feedback, concerns or reports of CSAE or CSAM, namely, the Headquarterz Video Reporting System provides a seamless way 
                for users to report inappropriate, harmful, or rule-violating content directly within the app. This system ensures quick moderation, enhances user safety, and 
                maintains the platform's content integrity.</p>

            <h2>Updates</h2>
            <p>Headquarterz may from time to time in its sole discretion develop and provide Platform updates, which may include upgrades, bug fixes, patches, other error 
                corrections, and/or new features (collectively, including related documentation, “Updates”). Updates may also modify or delete in their entirety certain features 
                and functionality. You agree that Headquarterz has no obligation to provide any Updates or to continue to provide or enable any particular features or functionality. 
                If you access the Services via a mobile Platform, and depending upon your mobile device settings, when your mobile device is connected to the internet either:</p>
            <p>(a) the Platform will automatically download and install all available Updates; or</p>
            <p>(b) you may receive notice of or be prompted to download and install available Updates.</p>
            <p>To the extent downloadable software is provided in connection with the Services and relation to the Platform, you shall promptly download and install all Updates and 
                acknowledge and agree that the Platform or portions thereof may not properly operate should you fail to do so. You further agree that all Updates will be deemed part 
                of the Platform and be subject to all terms and conditions of this Agreement.</p>

            <h2>Third-Party Materials</h2>
            <p>The Platform may display, include, or make available other users' and other third-party content (including data, information, Platforms, and other products, services, 
                and/or materials) or provide links to third-party websites or services, including through third-party advertising (“Third-Party Materials”). You acknowledge and 
                agree that Headquarterz is not responsible for Third-Party Materials, including their accuracy, completeness, timeliness, validity, copyright compliance, legality, 
                decency, quality, or any other aspect thereof. Headquarterz does not assume and will not have any liability or responsibility to you or any other person or entity 
                for any Third-Party Materials. Third-Party Materials and links thereto are provided solely as a convenience to you, and you access and use them entirely at your 
                own risk and subject to such third parties' terms and conditions.</p>

            <h2>Digital Millennium Copyright Act</h2>
            <p>Notification: If you are a copyright owner or an agent thereof and believe that any User Content infringes upon your copyright, you may submit a written notification 
                pursuant to the Digital Millennium Copyright Act (“DMCA”) (see 17 U.S.C. 512(c)(3) for further information) by providing our Copyright Agent (listed below) with the 
                following information:</p>
            <ul>
                <li>an electronic or physical signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed</li>
                <li>a description of the copyrighted work claimed to have been infringed or if multiple copyrighted works are covered by a notification, a representative list of 
                    such works at our Platform</li>
                <li>a description of the location on the Site of the allegedly infringing material(s); (iv) your address, telephone number, and email address</li>
                <li>Adequate information by which we can contact you (including your name, postal address, telephone number, and, if available, email address)</li>
                <li>a written statement that you have a good faith belief that use of the material(s) in the manner complained of is not authorized by the copyright owner, its 
                    agent, or the law</li>
                <li>a written statement that the information in the notification is accurate, and under penalty of perjury, that you are authorized to act on behalf of the 
                    owner of an exclusive right that is allegedly infringed</li>
            </ul>
            <p>Headquarterz' designated Copyright Agent for notice of claims of infringement is:</p>
            <p>Patrick J. Concannon, Esq.<br />
                Phone: 617-439-2177<br />
                Email: pconcannon@nutter.com</p>
            <p>Only notices of alleged copyright infringement should go to the Copyright Agent; any other feedback, comments, requests for technical support, and other communications 
                should be directed to Headquarterz' customer service through [email]. You acknowledge that if you fail to comply with all of the notice requirements of the DMCA, your 
                notice may not be valid. Please be aware that if you knowingly materially misrepresent that material or activity on the Platform is infringing your copyright, you may 
                be held liable for damages (including costs and attorneys' fees) under Section 512(f) of the DMCA.</p>
            <p>Counter-Notification: If you believe that any User Content of yours that was removed is not infringing, or that you have the appropriate rights from the copyright owner 
                or third party, or pursuant to the law, to post and use the material in your User Content, you may send a counter notification containing the following information to 
                the Copyright Agent:</p>
            <ul>
                <li>your physical or electronic signature</li>
                <li>identification of the material that has been removed or to which access has been disabled and the location at which the material appeared before it was removed 
                    or disabled</li>
                <li>a statement (under penalty of perjury) that you have a good faith belief that the content was removed or disabled as a result of mistake or a misidentification of 
                    the material</li>
                <li>Adequate information by which we can contact you (including your name, postal address, telephone number, and, if available, email address)</li>
                <li>your name, address, telephone number, and e-mail address, along with a statement that you consent to the jurisdiction of the federal court in Boston, Massachusetts 
                    and a statement that you will accept service of process from the person who provided notification of the alleged infringement</li>
            </ul>
            <p>If a counter notification is received by the Copyright Agent, Headquarterz may send a copy of such counter notification to the original notifying party. The original 
                notifying party shall have ten (10) business days to file an action for copyright infringement and seek a court order against the content provider or user posting 
                such material. If no such infringement action is filed within such 10 business days, we may, in our sole discretion, reinstate the removed material or cease disabling 
                such material.</p>
            <p>In accordance with the DMCA and other applicable law, Headquarterz may, in appropriate circumstances, terminate access, at Headquarterz' sole discretion, of any user 
                that we find to be a repeat infringer of others' copyrights.  Headquarterz may also, in its sole discretion, limit or fully terminate access to the Site of any user 
                infringing the intellectual property rights of others, regardless of whether such user is a repeat offender or not.</p>

            <h2>Representations and Warranties</h2>
            <p>You represent and warrant that:</p>
            <p>(a) you are in compliance and will comply with all laws applicable to your use of the Platform and Services;</p>
            <p>(b) you own or otherwise have and will have the necessary rights and consents in and relating to all content that you or any individual using the Platform under your 
                logon credentials submit to the Platform so that, as received by Headquarterz and stored and shared within the Platform, such content does not and will not infringe, 
                misappropriate, or otherwise violate any intellectual property rights, privacy right or other rights of any third party or violate any applicable law;</p>
            <p>(c) you have the right and authority to enter into this Agreement; and</p>
            <p>(d) your entering into this Agreement with Headquarterz and your performance of your obligations under the Agreement do not and will not conflict with or result in 
                any breach or default under any other agreement to which you are subject.</p>

            <h2>Term and Termination</h2>
            <p>(a) The term of this Agreement commences on the Effective Date and will continue in effect until terminated by you as set forth below in Section 14(b) or by Headquarterz 
                as set forth below in Section 14(c) (the “Term”).</p>
            <p>(b) You may terminate this Agreement by ceasing to use the Platform and, if you have downloaded the Platform in mobile app form, deleting the Platform and any copies 
                thereof from your mobile device.  If you no longer want to use our Platform again, and would like your account deleted, contact us at: [email address]. We will provide 
                you with further assistance and guide you through the process. Once you choose to delete your account, you will not be able to reactivate your account or retrieve any 
                of the content or information you have added.</p>
            <p>(c) Headquarterz may terminate this Agreement at any time without notice if it ceases to support the Platform, which Headquarterz may do in its sole discretion. In 
                addition, this Agreement will terminate immediately and automatically, and Headquarterz reserves the right to disable your or any individual’s access to the Platform, 
                without any notice if you violate any of the terms and conditions of this Agreement.  This Section 14(c) does not limit any of Headquarterz’ other rights or remedies, 
                whether at law, in equity, or under this Agreement.</p>
            <p>(d) Upon termination:</p>
            <ul>
                <li>all rights granted to you under this Agreement will also terminate; and</li>
                <li>you must cease all use of the Platform and, if applicable, delete all copies of the Platform from your mobile device and account.</li>
            </ul>
            <p>(e)  Termination will not limit any of Headquarterz’ rights or remedies at law or in equity.  Moreover, upon termination or expiration of this Agreement Sections 1, 5, 
                8, 9, 11, 13, 14(d), 14(e), 15, 16, 17 and 21 and any other provision of this Agreement that by its terms would be expected to survive termination or expiration of 
                this Agreement will survive termination or expiration of this Agreement.</p>

            <h2>Disclaimer of Warranties.</h2>
            <p>THE PLATFORM IS PROVIDED TO YOU “AS IS,” “AS AVAILABLE” AND WITH ALL FAULTS AND DEFECTS WITHOUT WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT 
                PERMITTED UNDER APPLICABLE LAW, HEADQUARTERZ, ON ITS OWN BEHALF AND ON BEHALF OF ITS AFFILIATES AND ITS AND THEIR RESPECTIVE LICENSORS AND SERVICE PROVIDERS, EXPRESSLY 
                DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, WITH RESPECT TO THE PLATFORM, INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS 
                FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT, AND WARRANTIES THAT MAY ARISE OUT OF COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE, OR TRADE PRACTICE. YOU 
                AGREE THAT HEADQUARTERZ CANNOT CONTROL AND IS NOT RESPONSIBLE FOR THE BEHAVIOR AND COMMUNICATIONS OF THIRD PARTIES, INCLUDING OTHER USERS. WITHOUT LIMITATION TO THE 
                FOREGOING, HEADQUARTERZ PROVIDES NO WARRANTY OR UNDERTAKING, AND MAKES NO REPRESENTATION OF ANY KIND THAT THE PLATFORM WILL MEET YOUR REQUIREMENTS, ACHIEVE ANY INTENDED 
                RESULTS, BE COMPATIBLE, OR WORK WITH ANY OTHER SOFTWARE, PLATFORMS, SYSTEMS, OR SERVICES, OPERATE WITHOUT INTERRUPTION, MEET ANY PERFORMANCE OR RELIABILITY STANDARDS 
                OR BE ERROR-FREE, OR THAT ANY ERRORS OR DEFECTS CAN OR WILL BE CORRECTED. HEADQUARTERZ TAKES NO RESPONSIBILITY WHATSOEVER FOR THE INFORMATION YOU HAVE UPLOADED TO THE 
                SERVICES AND SHALL NOT BE RESPONSIBLE OR LIABLE FOR THE DELETION, CORRECTION, DESTRUCTION, DAMAGE, OR LOSS OF SUCH INFORMATION, OR FAILURE TO STORE ANY OF SUCH 
                INFORMATION. NOR IS HEADQUARTERZ RESPONSIBLE FOR LOSS OF INFORMATION THROUGH THE ACTION OF ANY THIRD PARTY OR BECAUSE OF CIRCUMSTANCES BEYOND HEADQUARTERZ'S CONTROL.</p>
            <p>SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF OR LIMITATIONS ON IMPLIED WARRANTIES OR THE LIMITATIONS ON THE APPLICABLE STATUTORY RIGHTS OF A CONSUMER, SO IT COULD 
                BE THAT SOME OR ALL OF THE ABOVE EXCLUSIONS AND LIMITATIONS DO NOT APPLY TO YOU.</p>

            <h2>Limitation of Liability</h2>
            <p>TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL HEADQUARTERZ, ITS OFFICEERS, DIRECTORS, EMPLOYEES, AGENTS, PRINCIPALS OR ITS AFFILIATES, OR ANY OF 
                ITS OR THEIR RESPECTIVE LICENSORS OR SERVICE PROVIDERS, HAVE ANY LIABILITY ARISING FROM OR RELATED TO YOUR USE OF OR INABILITY TO USE THE PLATFORM OR THE CONTENT AND 
                SERVICES FOR:</p>
            <p>(a) PERSONAL INJURY, PROPERTY DAMAGE, LOST PROFITS, COST OF SUBSTITUTE GOODS OR SERVICES, LOSS OF DATA, LOSS OF GOODWILL, BUSINESS INTERRUPTION, COMPUTER FAILURE OR 
                MALFUNCTION, OR ANY OTHER CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL, OR PUNITIVE DAMAGES.</p>
            <p>(b) DIRECT DAMAGES IN AMOUNTS THAT IN THE AGGREGATE EXCEED ANY AMOUNTS ACTUALLY PAID BY YOU TO HEADQUARTERZ FOR USE OF THE PLATFORM DURING THE ONE (1) YEAR PERIOD 
                PRIOR TO THE EVENT THAT GIVES RISE TO YOUR CLAIM OF LIABILITY ON HEADQUARTERZ'S PART.</p>
            <p>HEADQUARTERZ IS NOT RESPONSIBLE OR LIABLE FOR ANY DELAY OR FAILURE OF PERFORMANCE CAUSED IN WHOLE OR IN PART BY YOUR DELAY IN PERFORMING, OR FAILURE TO PERFORM, ANY OF 
                YOUR OBLIGATIONS UNDER THIS AGREEMENT.  THE PLATFORM AND SERVICES DO NOT REPLACE THE NEED FOR YOU TO MAINTAIN REGULAR DATA BACKUPS OR REDUNDANT DATA ARCHIVES. 
                HEADQUARTERZ HAS NO OBLIGATION OR LIABILITY FOR ANY LOSS, ALTERATION, DESTRUCTION, DAMAGE, CORRUPTION, OR RECOVERY OF YOUR DATA.</p>
            <p>THE FOREGOING LIMITATIONS WILL APPLY WHETHER SUCH DAMAGES ARISE OUT OF BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE AND REGARDLESS OF WHETHER SUCH DAMAGES 
                WERE FORESEEABLE OR HEADQUARTERZ WAS ADVISED OF OR SHOULD HAVE BEEN AWARE OF THE POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS DO NOT ALLOW CERTAIN LIMITATIONS OF 
                LIABILITY SO SOME OR ALL OF THE ABOVE LIMITATIONS OF LIABILITY MAY NOT APPLY TO YOU.</p>

            <h2>Indemnification</h2>
            <p>You agree to indemnify, defend, and hold harmless Headquarterz and its affiliates and their respective officers, directors, employees, agents, affiliates, successors, 
                and assigns from and against any and all losses, damages, liabilities, deficiencies, claims, actions, judgments, settlements, interest, awards, penalties, fines, costs, 
                or expenses of whatever kind, including attorneys' fees (collectively “Claims”), arising from or relating to your use or misuse of the Platform or Services or your 
                breach of this Agreement, including but not limited to the content you submit or make available through this Platform.  Headquarterz reserves the right to assume 
                the exclusive defense and control of any matter subject to immediate indemnification.</p>
 
            <h2>Geographic Restrictions</h2>
            <p>Headquarterz makes no claims that the Platform, any of its content, or the Services are accessible or lawful outside of the United States. Access to the Platform and 
                use of the Services might not be legal by certain persons or in certain countries. If you access the Platform from outside the United States, you do so on your own 
                initiative and are responsible for compliance with local laws.  Furthermore, by agreeing to the terms of this Agreement you consent to the processing of your personal 
                data in the United States.</p>
 
            <h2>Severability</h2>
            <p>If any provision of this Agreement is illegal or unenforceable under applicable law, the remainder of the provision will be amended to achieve as closely as possible 
                the effect of the original term and all other provisions of this Agreement will continue in full force and effect; provided, however, that if any fundamental term 
                or provision of this Agreement is invalid, illegal, or unenforceable, the remainder of this Agreement shall be unenforceable.</p>

            <h2>Governing Law & Venue</h2>
            <p>This Agreement is governed by and construed in accordance with the internal laws of the Commonwealth of Massachusetts, without giving effect to any choice or conflict 
                of law provision or rule. Any legal suit, action, or proceeding arising out of or related to this Agreement or the Platform shall be instituted exclusively either 
                in federal court in the United States District Court for Massachusetts in Boston or in the courts of the Commonwealth of Massachusetts in Suffolk County. You waive 
                any and all objections to the exercise of jurisdiction over you by such courts and to venue in such courts.</p>

            <h2>Limitation of Time to File Claims</h2>
            <p>ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THIS AGREEMENT OR THE PLATFORM MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION 
                ACCRUES OTHERWISE SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.</p>

            <h2>Arbitration Agreement</h2>
            <p>You agree that in the event of any dispute between you and Headquarterz, you will first contact Headquarterz and make a good faith sustained effort to resolve the 
                dispute before resorting to more formal means of resolution, including without limitation any court action.  After the informal dispute resolution process any 
                remaining dispute, controversy, or claim (collectively, “Claim”) relating in any way to your use of the Platform or the Services, or relating in any way to the 
                communications between you and Headquarterz or any other user of the Services, will be finally resolved by binding arbitration. This mandatory arbitration agreement 
                applies equally to you and Headquarterz. However, this arbitration agreement does not (a) govern any Claim by Headquarterz for infringement of its intellectual 
                property or access to the Platform that is unauthorized or exceeds authorization granted in these Terms or (b) bar you from making use of applicable small claims 
                court procedures in appropriate cases. If you are an individual, you may opt out of this arbitration agreement within thirty (30) days of the first of the date you 
                access or use the Platform by following the procedure described below.</p>
            <p>You agree that the U.S. Federal Arbitration Act governs the interpretation and enforcement of this provision, and that you and Headquarterz are each waiving the 
                right to a trial by jury or to participate in a class action. This arbitration provision will survive any termination of this Agreement.</p>
            <p>If you wish to begin an arbitration proceeding, after following the informal dispute resolution procedure, you must send a letter requesting arbitration and 
                describing your claim to:</p>
            <p>Headquarterz Inc.<br />
                3133 W Frye Rd,<br />
                Chandler, AZ 85226 USA</p>
            <p>Email us: legal@hqz.gg</p>

            <p>The arbitration will be administered by the American Arbitration Association (AAA) under its rules including, if you are an individual, the AAA's Supplementary 
                Procedures for Consumer-Related Disputes. If you are not an individual or have used the Services on behalf of an entity, the AAA's Supplementary Procedures for 
                Consumer-Related Disputes will not be used. The AAA's rules are available at www.adr.org or by calling 1-800-778-7879.</p>
            <p>Payment of all filing, administration and arbitrator fees will be governed by the AAA's rules. If you are an individual and have not accessed or used the Platform 
                on behalf of an entity, we will reimburse those fees for claims where the amount in dispute is less than $10,000, unless the arbitrator determines the claims are 
                frivolous, and we will not seek attorneys' fees and costs in arbitration unless the arbitrator determines the claims are frivolous.</p>
            <p>The arbitrator, and not any federal, state, or local court, will have exclusive authority to resolve any dispute relating to the interpretation, applicability, 
                unconscionability, arbitrability, enforceability, or formation of this arbitration agreement, including any claim that all or any part of this arbitration agreement 
                is void or voidable. However, the preceding sentence will not apply to the “Class Action Waiver” section below.</p>
            <p>If you do not want to arbitrate disputes with Headquarterz and you are an individual, you may opt out of this arbitration agreement by sending an email to legal@hqz.gg 
                within thirty (30) days of the first of the date you access or use the Platform.</p>

            <h2>Class Action Waiver</h2>
            <p>Any Claim must be brought in the respective party's individual capacity, and not as a plaintiff or class member in any purported class, collective, representative, 
                multiple plaintiffs, or similar proceeding (“Class Action”). The parties expressly waive any ability to maintain any Class Action in any forum. If the Claim is subject 
                to arbitration, the arbitrator will not have authority to combine or aggregate similar claims or conduct any Class Action nor make an award to any person or entity 
                not a party to the arbitration. Any claim that all or part of this Class Action Waiver is unenforceable, unconscionable, void, or voidable may be determined only by 
                a court of competent jurisdiction and not by an arbitrator. The parties understand that any right to litigate in court, to have a judge or jury decide their case, 
                or to be a party to a class or representative action, is waived, and that any claims must be decided individually, through arbitration.  If this class action waiver 
                is found to be unenforceable, then the entirety of the Arbitration Agreement, if otherwise effective, will be null and void. The arbitrator may award declaratory or 
                injunctive relief only in favor of the individual party seeking relief and only to the extent necessary to provide relief warranted by that party's individual claim. 
                If for any reason a claim proceeds in court rather than in arbitration, you and Headquarterz each waive any right to a jury trial.</p>

            <h2>Entire Agreement</h2>
            <p>This Agreement, our Privacy Policy and any other terms that you are presented with while navigating the Platform and engaging in transactions through the Platform 
                constitute the entire agreement between you and Headquarterz with respect to the Platform and Services and supersede all prior or contemporaneous understandings and 
                agreements outside of this Agreement or outside of the Platform, whether written or oral, with respect to the Platform and Services.</p>

            <h2>Waiver</h2>
            <p>No failure to exercise, and no delay in exercising, on the part of either party, any right or any power hereunder shall operate as a waiver thereof, nor shall any 
                single or partial exercise of any right or power hereunder preclude further exercise of that or any other right hereunder. In the event of a conflict between this 
                Agreement and any applicable purchase or other terms, the terms of this Agreement shall govern.</p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default UserAgreement;