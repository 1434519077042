import React from 'react';
import Header from './common/Header';
import Footer from './common/Footer';
import SEO from './common/SEO';
//import { Link } from 'react-router-dom';

const Parents = () => {
  return (
    <>
      <SEO title="Children's Privacy Policy - HQZ" />
      <div className="position-relative bg-black">
        <Header />
        <div className="container py-5 text-white">
            <h1 className="mb-5">Children's Privacy Policy</h1>
            <p><em>Effective Date: September 30, 2024</em></p>

            <p>The Children's Online Privacy Protection Act of 1998 and its rules require Headquarterz Inc. (“Headquarterz”, “us”, “we” or “our”) to inform parents, legal 
                guardians (collectively, “you”) about our practices for collecting, using, and disclosing Personal Information from children under the age of 13 
                (“children”) in connection with the Headquarterz interactive sports-themed social video sharing and viewing platforms (the “Platforms”). It also 
                requires us to obtain verifiable consent from a child's parent or guardian under for certain collection, use, and disclosure of the child's Personal 
                Information.</p>
            <p>This Children's Privacy Policy notifies you of:</p>
            <ul>
                <li>The types of information we may collect from children.</li>
                <li>How we use the Personal Information we collect.</li>
                <li>Our practices for disclosing that information.</li>
                <li>Our practices for notifying and obtaining your consent when we collect Personal Information from children, including how you may revoke consent.</li>
                <li>All operators that collect or maintain information from children through this platform.</li>
            </ul>
            <p>This Children's Privacy Policy only applies to children under the age of 13 and supplements our general Privacy Policy. Only our general Privacy Policy 
                applies to teens and adults.</p>
            <p>Terms that are defined in Headquarterz's general Privacy Policy, including Personal Information, have the same meanings as used in this Children's Privacy Policy.</p>
            
            <h2>Information We Collect from Children</h2>
            <p>As a result of the child's use of the Platforms, we gain visibility into and collect a wide range of information about the child's web page visits and web navigations.</p>
            
            <h2>Information We Collect Directly</h2>
            <p>A child must provide us with the following information to register: the child's first name and the child's parent or guardian email address. We may also require the 
                child to create a member name and password. We may request additional information from the child, but this information is optional. We specify whether information 
                is required or optional when we request it.</p>

            <h2>Automatic Information Collection and Tracking</h2>
            <p>We use certain technologies, such as cookies, to automatically collect information from our users (including children) when they visit or use the Software.</p>
            <p>While the web browser extension software is downloaded and associated with a child web browser, we have direct visibility into and my capture all of the content that 
                the child gives us access to via his or her web browser, which includes the articles, images, web pages and all other content.  All of the content that a child gives 
                us access to when using a web browser with the Headquarterz software downloaded qualifies as Usage Information for purposes of the main Privacy Policy.</p>
            <p>We use technology to automatically collect information from our users, including children, when they access and navigate through the Platforms and use certain of 
                its features.</p>
            <p>For information about our automatic information collection practices, including how you can opt out of certain information collection, see the “Information Collected 
                or Stored As You Access and Use the Site” section of our general Privacy Policy.</p>

            <h2>How We Use Your Child’s Information</h2>
            <p>We use the Personal Information we collect from your child to:</p>
            <ul>
                <li>register him or her with the Software;</li>
                <li>communicate with him or her about activities or features of the Platforms that may be of interest.</li>
            </ul>
            <p>We use the information we collect automatically through technology (see Automatic Information Collection and Tracking) and other non-personal information we collect 
                to improve our Platform and to deliver a better and more personalized experience by enabling us to:</p>
            <ul>
                <li>Estimate our audience size and usage patterns.</li>
                <li>Store information about the child's preferences, allowing us to customize the content according to individual interests.</li>
            </ul>
            
            <h2>Our Practices for Disclosing Children's Information</h2>
            <p>We will not ever share, sell, rent, or transfer children's Personal Information other than as described in this section.</p>
            <p>We may disclose aggregated information about many of our users, and information that does not identify an individual.  In addition, wee may disclose children's 
                Personal Information:</p>
            <ul>
                <li>To third parties to market their products or services to the child, if you have not opted out of such disclosures.</li>
                <li>To third parties we use to support the internal operations of our Software and who are bound by contractual or other obligations to use the information 
                    only for such purpose and to keep the Personal Information confidential.</li>
                <li>If we are required to do so by law or legal process, such as to comply with any court order or subpoena or to respond to any government or regulatory request.</li>
                <li>If we believe disclosure is necessary or appropriate to protect the rights or property of Headquarterz, our customers or others, including to:
                    <ul>
                        <li>protect the safety of a child;</li>
                        <li>protect the safety and security of the Platform; or</li>
                        <li>enable us to take precautions against liability.</li>
                    </ul>
                </li>
                <li>To law enforcement agencies or for an investigation related to public safety.</li>
            </ul>
            <p>In addition, we may share Personal Information with our parent, subsidiaries and affiliates.  We also reserve the right to disclose and transfer all such 
                information: (i) to a subsequent owner, co-owner or operator of the Application or applicable database; or (ii) in connection with a merger, consolidation, 
                restructuring, the sale of substantially all of our interests and/or assets or other corporate change, including, during the course of any due diligence 
                process.</p>
            
            <h2>Accessing and Correcting Your Child's Personal Information</h2>
            <p>At any time, you may review the child's Personal Information maintained by us, require us to correct or delete the Personal Information, and/or refuse to permit us 
                from further collecting or using the child's information.</p>
            <p>You can review, change, or delete your child's Personal Information by:</p>

            <h2>Operators That Collect or Maintain Information from Children</h2>
            <p>No other operators may collect or maintain Personal Information from children through the Software.</p>
            <p>Please direct any inquiries about any operator's privacy practices and use of children's information to us using the following contact 
                information:</p>
            <p>Headquarterz Inc.<br />
                3133 W Frye Rd,<br />
                Chandler, AZ 85226 USA</p>
            <p>Email us: legal@hqz.gg</p>
          </div>
        </div>
      <Footer />
    </>
  );
};

export default Parents;
