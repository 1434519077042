import React from 'react';
import Header from './common/Header';
import Footer from './common/Footer';
import SEO from './common/SEO';

const Privacy = () => {
  return (
    <>
      <SEO title="Privacy Policy - HQZ" />
      <div className="position-relative bg-black">
        <Header />
        <div className="container py-5 text-white">
            <h1 className="mb-5">Privacy Policy</h1>
            <p><em>Effective Date: September 30, 2024</em></p>

            <p>Welcome! You have arrived at Headquarterz Inc.'s (“we”, “us” or “Headquarterz”) websites at www.hqz.gg, www.hqztv.com and www.hqz.app (collectively the 
                “Sites”) and the associated interactive sports-themed social video sharing and viewing platforms operated by Headquarterz (the platforms together 
                collectively referred to as the “Platform”).  Throughout this Privacy Policy the words “we”, “us” and “our” refer to Headquarterz, and the words “you” 
                and “your” refer to you or, as appropriate, the user (such as your child or a minor under your guardianship) visiting the Sites or the Platform.  This 
                Privacy Policy applies to information that we collect at and through the Sites and the Platform (including any mobile app associated with the Sites or 
                the Platform) and our handling of such information.  This Privacy Policy does not apply to our data collection activities offline or otherwise outside 
                of the Sites or the Platform (unless otherwise stated below).  Please review the Sites' Website Terms of Use and, if you are a registered user, the 
                Platform's User Agreement, which govern your use of the Platform and any supporting mobile application.</p>
            <p><em>By accessing and using the Sites or the Platform, you consent to our collection, use and sharing of your (or, as appropriate, your child or a child 
                under your guardianship's) information and data, and other activities, as described below.</em></p>

            <h2>What Information Do the Sites and Platform Collect?</h2>
            <h4>Information You Provide to Us</h4>
            <p>Personal Information.  We may ask you, your child or a minor under your guardianship to provide us with, or you might submit via the Sites or the 
                Platform, personally identifiable information, which is information that identifies an individual personally, such as one's first and last name, 
                telephone number and e-mail address (“Personal Information”).  We may collect this Personal Information through various forms and in various places 
                on the Platform, including account registration forms, “contact us forms”, or when you otherwise upload data to and interact with the Platform.</p>
            <h4>Information Collected or Stored As You Access and Use the Sites or Platform</h4>
            <p>In addition to any Personal Information or other information that you choose to submit to us via the Sites or the Platform, we and our third-party service 
                providers may use a variety of technologies that automatically (or passively) store or collect certain information whenever you visit or interact with 
                the Sites or the Platform (“Usage Information”).  This Usage Information may be stored or accessed using a variety of technologies that may be 
                downloaded to your personal computer, browser, laptop, tablet, mobile phone or other device (a “Device”) whenever you visit or interact with the 
                Sites or the Platform.  To the extent we associate Usage Information with Personal Information we collect directly from you on the Sites or the 
                Platform, we will treat it as Personal Information.</p>
            <p>Usage Information may include:</p>
            <ul>
                <li>your IP address, UDID or other unique identifier (“Device Identifier”).  A Device Identifier is a number that is automatically assigned to your 
                    Device used to access the Platform, and our computers identify your Device by its Device Identifier;</li>
                <li>the URL that referred you to our Platform;</li>
                <li>the areas within our Sites or Platform that you visit and your activities there, including remembering you and your preferences;</li>
                <li>your Device location;</li>
                <li>your Device characteristics; and</li>
                <li>certain other Device data, including the time of day, among other information.</li>
            </ul>
            <p><b>Tracking Technologies.</b>  We may use various methods and technologies to store or collect Usage Information (“Tracking Technologies”).  Tracking Technologies 
                may set, change, alter or modify settings or configurations on your Device. A few of the Tracking Technologies include, without limitation, the following 
                (and subsequent technology and methods later developed):</p>
            <p><b>Cookies.</b>  A cookie is a data file placed on a Device when it is used to visit the Sites or the Platform.  A Flash cookie (or locally shared object) is a 
                data file placed on a Device via the Adobe Flash plug-in that may be built-in to or downloaded by you to your Device.  HTML5 cookies can be programmed 
                through HTML5 local storage.</p>
            <p><b>Web Beacons.</b>  Small graphic images or other web programming code called web beacons (also known as “1x1 GIFs” or “clear GIFs”) may be included in the Sites 
                or the Platform's pages and messages.  Web beacons may be invisible to you, but any electronic image or other web programming code inserted into a page or 
                e-mail can act as a web beacon.  Web beacons or similar technologies may be used for a number of purposes, including, without limitation, to count visitors 
                to the Platform, to monitor how users navigate the Sites or the Platform, to count how many e-mails that were sent were actually opened or to count how many 
                particular articles or links were actually viewed.</p>
            <p><b>Embedded Scripts.</b>  An embedded script is programming code that is designed to collect information about your interactions with the Sites or the Platform, 
                such as the links you click on.  The code is temporarily downloaded onto your Device from our web server or a third-party service provider, is active only 
                while you are connected to the Sites or the Platform, and is deactivated or deleted thereafter.</p>  
            <p><b>Browser Fingerprinting.</b>  Collection and analysis of information from your Device, such as, without limitation, your operating system, plug-ins, system fonts 
                and other data, for purposes of identification.</p>
            <p><b>ETag, or Entity Tag.</b>  A feature of the cache in browsers.  It is an opaque identifier assigned by a web server to a specific version of a resource found at a 
                URL.  If the resource content at that URL ever changes, a new and different ETag is assigned.  Used in this manner ETags are a form of Device Identifier.  
                ETag tracking may generate unique tracking values even where the consumer blocks HTTP, Flash, and/or HTML5 cookies.</p>
            <p><b>Recognition Technologies.</b>  Technologies, including application of statistical probability to data sets, which attempt to recognize or make assumptions about 
                users and devices (e.g., that a user of multiple devices in the same user).</p>
            <p><b>Tracking Technologies Usage.</b>  We may use Tracking Technologies for a variety of purposes, including:</p>
            <p><b>Strictly Necessary.</b>  We may use cookies or other Tracking Technologies that we consider are strictly necessary to allow you to use and access the Sites or 
                the Platform, including cookies required to prevent fraudulent activity and improve security.</p>
            <p><b>Performance Related.</b>  We may use cookies or other Tracking Technologies that are useful in order to assess the performance of the Sites or the Platform, 
                including as part of our analytic practices or otherwise to improve the content, products or services offered through the Sites or the Platform.</p>
            <p><b>Targeting Related.</b>  We may use Tracking Technologies to deliver content relevant to your interests on the Sites or the Platform and thirdparty sites based 
                on how you interact with our content.  This includes using Tracking Technologies to understand the usefulness to you of the content that has been delivered 
                to you.</p>
            <p><b>Tracking Technologies Choices and Consent.</b> We obtain your consent to our information storage or collection Tracking Technologies by providing you with 
                transparent information in our Privacy Policy and providing you with the opportunity to make a choice to disable cookies as set forth above.  Please note 
                that we are not required to obtain your consent to the information collection Tracking Technologies identified above that are strictly necessary.</p>
            <p>We are giving you detailed notice of the Tracking Technologies and your limited choices regarding them so that your consent is meaningfully informed.</p>
            <p><b>Third Party Tracking and “Do Not Track.”</b> There may be other Tracking Technologies now and later devised and used by us in connection with the Sites or the 
                Platform.  Further, third parties may use Tracking Technologies in connection with our Platform, which may include the collection of information about your 
                online activities over time and across third-party websites or online services.  We may not control those Tracking Technologies and we are not responsible 
                for them.  However, you consent to potentially encountering third-party Tracking Technologies in connection with use of the Sites or the Platform and accept 
                that our statements under this Privacy Policy do not apply to the Tracking Technologies or practices of such third parties.  Also, various third parties are 
                developing or have developed signals or other mechanisms for the expression of consumer choice regarding the collection of information about an individual 
                consumer's online activities over time and across third-party web sites or online services (e.g., browser do not track signals).  Currently, we do not 
                monitor or take any action with respect to these signals or other mechanisms.</p>
            <h4>Information Third Parties Provide About You</h4>
            <p>We may, from time to time, supplement the information we collect directly from you on the Sites or the Platform with outside records from third parties for 
                various purposes, including to enhance our ability to serve you, to tailor our content to you and to offer you opportunities that may be of interest to you.  
                To the extent we combine information we receive from those sources with your Personal Information we collect on the Sites or the Platform, it will be treated 
                as Personal Information and we will apply this Privacy Policy to such combined information, unless we have disclosed otherwise.  In no other circumstances do 
                our statements under this Privacy Policy apply to information we receive about you from third parties, even if they have used our technology to collect it 
                and share it with us.</p>
            <h4>Interactions with Third-Party Platforms</h4>
            <p>The Sites or the Platform may include functionality that allows certain kinds of interactions between the Sites or the Platform and a third-party website or 
                application.  The use of this functionality may involve the third-party operator providing certain information, including Personal Information, to us.  For 
                example, when you register with the Platform, you may have an option to use your Facebook, Google or other account provided by a third-party site or 
                application to facilitate the registration and log-in or transaction process on the Platform, or otherwise link accounts.  If we offer and you choose to use 
                this functionality to access or use our Platform, the third-party site or application may send Personal Information about you to us.  If so, we will then 
                treat it as Personal Information under this Privacy Policy, since we are collecting it as a result of your accessing of and interaction on the Sites or the 
                Platform. In addition, we may provide third-party sites' interfaces or links on the Sites or the Platform to facilitate your sending a communication from 
                the Sites or the Platform.  For example, we may use third parties to facilitate emails, tweets or Facebook postings.  These third parties may retain any 
                information used or provided in any such communications or other activities and these third parties' practices are not subject to our Privacy Policy.  
                Headquarterz may not control or have access to your communications through these third parties.  Further, when you use third-party websites or services, 
                you are using their services and not our services and they, not we, are responsible for their practices.  You should review the applicable third-party 
                privacy policies before using such third-party tools on the Sites or the Platform.</p>

            <h2>How Do We Use the Information Collected?</h2>
            <p>Generally.  We may use your Personal Information or Usage Information that we collect about you:  (1) to provide you with information or services or to process 
                transactions that you have requested or agreed to receive; (2) to provide you with information via email about new Headquarterz product or service offerings 
                on an ongoing basis, unless you opt out of receiving such emails; (3) to enable you to participate in a variety of the Sites or the Platform's features; (4) 
                to send promotional or marketing emails to you on behalf of the other businesses or organizations; (5) to process your account inquiry, including verifying 
                your information is active and valid; (6) to improve the Sites or the Platform, to create new service offerings, to customize your experience on the Sites or 
                the Platform, or to serve you specific content that is most relevant to you; (7) to contact you with regard to your use of any of the Sites or the Platform 
                and, in our discretion, changes to the Sites or the Platform and/or any of the Sites or the Platform's policies; (8) for internal business purposes; and (9) 
                for purposes disclosed at the time you provide your information or as otherwise set forth in this Privacy Policy.</p>
            <p>Contact Us.  Please note that information submitted to the Sites or the Platform via a “contact us” or other similar customer inquiry function may not receive a 
                response.</p>

            <h2>How and When Do We Share Information with Third Parties?</h2>
            <p>We may disclose your Personal Information to third parties to market their products or services to you if you have not opted out of these disclosures.  We also 
                may share non-Personal Information, such as aggregated user statistics and browsing behavior information, with third parties.  We may share the information we 
                have collected, including Personal Information, as disclosed at the time you provide the information and as described below or otherwise in this Privacy 
                Policy.  Headquarterz may also disclose Personal Information as follows:</p>
            <h4>Third Parties Providing Platforms on Our Behalf.</h4>
            <p>We may use third-party vendors to perform certain services on behalf of us or the Platform, such as hosting the Platform, designing and/or operating the 
                Platform's features, tracking the Platform's activities and analytics, and enabling us to send you special offers or perform other administrative services.  
                We may provide these vendors with access to user information, including Device Identifiers and Personal Information, to carry out the services they are 
                performing for you or for us.  Third-party analytics and other service providers may set and access their own Tracking Technologies on your Device and they 
                may otherwise collect or have access to Personal Information.  We are not responsible for those third-party technologies or activities arising out of them, 
                nor are we responsible for the effectiveness of or compliance with any third party's opt-out options.</p>
            <h4>To Protect the Rights of Headquarterz and Others.</h4>
            <p>To the fullest extent permitted by applicable law, we may also disclose Personal Information if we believe in good faith that doing so is necessary or 
                appropriate to: (i) protect or defend the rights, safety or property of Headquarterz or third parties (including through the enforcement of this Privacy 
                Policy, our Terms of Use, the User Agreement or other applicable agreements and policies); or (ii) comply with legal and regulatory obligations (e.g., 
                pursuant to law enforcement inquiries, subpoenas or court orders).  To the fullest extent permitted by applicable law, we have complete discretion in 
                electing to make or not make such disclosures, and to contest or not contest requests for such disclosures, all without notice to you.</p>
            <h4>Affiliates and Business Transfer.</h4>
            <p>We may share Personal Information with our parent, subsidiaries and affiliates.  We also reserve the right to disclose and transfer all such information: 
                (i) to a subsequent owner, co-owner or operator of the Sites or the Platform; or (ii) in connection with a merger, consolidation, restructuring, the sale 
                of substantially all of our interests and/or assets or other corporate change, including, during the course of any due diligence process.</p>

            <h2>What About Information I Disclose Publicly or to Others?</h2>
            <p>User-Generated Content and Public Information.  The Platform also permits you to submit ideas, photographs, user profiles, writings, music, video, streamed 
                and recorded audio content, computer graphics, pictures, data, questions, comments, suggestions or other content, including Personal Information 
                (collectively, “User Content”), including as part of your profile.  We or others may store, display, reproduce, publish, distribute or otherwise use User 
                Content online or offline in any media or format (currently existing or hereafter developed) and may or may not attribute it to you.</p>
            <p>Others may have access to this User Content and may have the ability to share it with third parties.  For example, if you upload images to the Platform, 
                you should consider avoiding uploading images with embedded location data (EXIF GPS) included. Other users of the Platform can download and extract any 
                location data from images posted on the Platform. Please think carefully before deciding what information you share, including Personal Information, in 
                connection with your User Content.  Please note that Headquarterz does not control who will have access to the information that you choose to make public, 
                and cannot ensure that parties who have access to such publicly available information will respect your privacy or keep it secure.  We are not responsible 
                for the privacy or security of any information that you make publicly available via the Platform or what others do with information you share with them on 
                the Platform.  We are not responsible for the accuracy, use or misuse of any User Content that you disclose or receive from third parties through the 
                Platform.</p>   
            <p>Your User Content is posted and transmitted to others at your own risk.  Although you may set certain privacy settings for such information by logging into 
                your account profile, please be aware that no security measures are perfect or impenetrable.  Additionally, we cannot control the actions of third parties 
                with whom you may choose to share your User Content. Therefore, we cannot and do not guarantee that your User Content will not be viewed by unauthorized 
                persons.</p>
            <p>For full terms and conditions regarding User Content you submit to the App, please review the Terms of Use.</p>

            <h2>Does Third-Party Content or Links To Third-Party Platforms Appear on the Platform?</h2>
            <p>The Platform may contain content that is supplied by a third party, and those third parties may collect Usage Information and your Device Identifier when 
                pages from the Platform are served to you.  In addition, when you are on the Platform you may be directed to other services that are operated and controlled 
                by third parties that we do not control.  We are not responsible for the data collection and privacy practices employed by any of these third parties or 
                their services and they may be tracking you across multiple sites and may be sharing the results of that tracking with us and/or others.  For example, if 
                you “click” on a link, the “click” may take you off the Platform onto a different site.  These other sites may associate their Tracking Technologies with 
                you, independently collect data about you or others, including Personal Information, and may or may not have their own published privacy policies.  We 
                encourage you to note when you leave our Platform and to review the third-party privacy policies of all third-party websites and exercise caution in 
                connection with them.</p>

            <h2>How Do I Change My Information and Communications Preferences?</h2>
            <p>You are responsible for maintaining the accuracy of the information you submit to us, such as your contact information provided as part of registration.  
                The Platform may allow you to review, correct or update Personal Information you have provided through the Platform's registration forms or otherwise, 
                and you may provide registration updates and changes by contacting us.  If so, we will make good faith efforts to make requested changes in our then 
                active databases as soon as reasonably practicable (but we may retain prior information as business records).  Please note that it is not always possible 
                to completely remove or delete all of your information from our databases and that residual data may remain on backup media or for other reasons. When you 
                edit your Personal Information or change your preferences on the Platform, information that you remove may persist internally for Headquarterz's 
                administrative purposes.  You may cancel or modify our e-mail marketing communications you receive from us by following the instructions contained within 
                our promotional e-mails or in some cases by logging into your Platform account and changing your communication preferences.  This will not affect subsequent 
                subscriptions and if your opt-out is limited to certain types of e-mails the opt-out will be so limited.  Please note that we reserve the right to send you 
                certain communications relating to your account or use of our Platform, such as administrative and service announcements and these transactional account 
                messages may be unaffected if you choose to opt-out from receiving our marketing communications.  If you have any questions about the Privacy Policy or 
                practices described in it, you should contact us in the following ways: Postal Mail: Attention: Privacy Officer, Headquarterz Inc., 3133 W Frye Rd,
                Chandler, AZ 85226 USA; By e-mail: legal@hqz.gg</p>

            <h2>What About Transfer of Information to the United States?</h2>
            <p>Our Platform is operated in the United States.  If you are located outside of the United States, please be aware that information we collect, including 
                Personal Information, will be transferred to, and processed, stored and used in the United States.  The data protection laws in the United States may 
                differ from those of the country in which you are located, and your Personal Information may be subject to access requests from governments, courts, or 
                law enforcement in the United States according to laws of the United States.  By using the Platform or providing us with any information, you consent to 
                the transfer to, and processing, usage, sharing and storage of your information, including Personal Information, in the United States as set forth in 
                this Privacy Policy.</p>

            <h2>What Should Parents Know About Children?</h2>
            <p>We understand the importance of protecting children's privacy in the interactive world.  Please see our Privacy Policy for Children Under the Age of 13 for more details.</p>

            <h2>State Privacy Rights</h2>
            <p>State consumer privacy laws may provide their residents with additional rights regarding our use of their personal information.</p>   
            <p>California's "Shine the Light" law (Civil Code Section § 1798.83) permits users of the Sites or the Platform who are California residents to request certain 
                information regarding our disclosure of Personal Information to third parties for their direct marketing purposes. To make such a request, please send an 
                email to legal@hqz.gg.  To learn more about California residents' privacy rights, visit Privacy Notice for California residents.</p>
            <p>California, Colorado, Connecticut, Delaware, Florida, Indiana, Iowa, Montana, Oregon, Tennessee, Texas, Utah, and Virginia provide (now or in the future) their 
                state residents with rights to:</p>
            <ul>
                <li>Confirm whether we process their personal information.</li>
                <li>Access and delete certain personal information.</li>
                <li>Correct inaccuracies in their personal information, taking into account the information's nature processing purpose (excluding Iowa and Utah).</li>
                <li>Data portability.</li>
                <li>Opt-out of personal data processing for:
                    <ul>
                        <li>targeted advertising (excluding Iowa);</li>
                        <li>sales; or</li>
                        <li>profiling in furtherance of decisions that produce legal or similarly significant effects (excluding Iowa and Utah).</li>
                    </ul>
                </li>
                <li>Either limit (opt-out of) or require consent to process sensitive personal data.</li>
            </ul>
            <p>Nevada provides its residents with a limited right to opt-out of certain personal information sales.</p>
            <p>The exact scope of these rights may vary by state.  To exercise any of these rights or to appeal a decision regarding a consumer rights request, please contact 
                us at legal@hqz.gg.</p>

            <h2>What About Security?</h2>
            <p>We endeavor to incorporate commercially reasonable safeguards to help protect and secure Personal Information.  However, no data transmission over the Internet, 
                mobile networks, wireless transmission or electronic storage of information can be guaranteed to be 100% secure.  Please note that we cannot ensure the security 
                of any information you transmit to us, and you use our Platform and provide us with your information at your own risk.</p>

            <h2>What About Changes to the Privacy Policy?</h2>
            <p>We reserve the right to change this Privacy Policy at any time.  Any changes will be effective immediately upon the posting of the revised Privacy Policy and 
                your use of our Platform indicates your consent to the privacy policy posted at the time of use.  However, we will not use your previously collected Personal 
                Information in a manner materially different than represented at the time it was collected without your consent.  To the extent any provision of this Privacy 
                Policy is found by a competent tribunal to be invalid or unenforceable, such provision shall be severed to the extent necessary for the remainder to be valid 
                and enforceable.</p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Privacy;